import LoginView from '@/views/Auth/LoginView/LoginView.vue'
import axios from 'axios'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

const AccountListView = () =>
  import('@/views/Accounts/AccountListView/AccountListView.vue')
const AccountDetailView = () =>
  import('@/views/Accounts/AccountDetailView/AccountDetailView.vue')
const NewCustomerRegistrationView = () =>
  import(
    '@/views/Auth/NewCustomerRegistrationView/NewCustomerRegistrationView.vue'
  )
const RegistrationConfirmationView = () =>
  import(
    '@/views/Auth/RegistrationConfirmationView/RegistrationConfirmationView.vue'
  )
const MerchantCustomerRegistration = () =>
  import('@/views/Auth/MerchantCustomerRegistration.vue')

const MessageSettingsView = () =>
  import('@/views/Accounts/MessageSettingsView/MessageSettingsView.vue')
const ResetAuth = () => import('@/views/Auth/ResetAuth/ResetAuth.vue')
const ForgotAuth = () => import('@/views/Auth/ForgotAuth/ForgotAuth.vue')
const PaymentMethodListView = () =>
  import(
    '@/views/PaymentMethods/PaymentMethodListView/PaymentMethodListView.vue'
  )
const InvalidTokenView = () =>
  import('@/views/InvalidTokenView/InvalidTokenView.vue')
const PaymentListView = () => import('@/views/Payment/PaymentListView.vue')
const PaymentDetailView = () => import('@/views/Payment/PaymentDetailView.vue')
// const SendLoginLinkView = () => import('@/views/Auth/SendLink/SendLink.vue')
const PageNotFound = () =>
  import('@/views/PageNotFoundView/PageNotFoundView.vue')

const PublicPaymentView = () =>
  import('@/views/PublicPayment/PublicPaymentView.vue')

import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/public-payment/:token',
    name: 'PublicPaymentView',
    component: PublicPaymentView,
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      publicRoute: true,
      showNav: false
    }
  },
  {
    path: '/register/:inviteCode',
    name: 'NewCustomerRegistration',
    component: NewCustomerRegistrationView,
    meta: {
      publicRoute: true,
      showNav: false
    }
  },
  {
    path: '/merchant/:merchantID/register',
    name: 'MerchantCustomerRegistration',
    component: MerchantCustomerRegistration,
    meta: {
      publicRoute: true,
      showNav: false
    }
  },
  {
    path: '/merchant/:merchantID/login',
    name: 'MerchantLogin',
    component: LoginView,
    meta: {
      publicRoute: true,
      showNav: false
    }
  },
  {
    path: '/confirm-email/:confirmID/:confirmToken',
    name: 'ConfirmRegistration',
    component: RegistrationConfirmationView,
    meta: {
      publicRoute: true,
      showNav: false
    }
  },
  {
    path: '/',
    name: 'AccountList',
    component: AccountListView
  },
  {
    path: '/account/:accountID',
    name: 'AccountDetail',
    component: AccountDetailView
  },
  {
    path: '/new-password',
    name: 'ForgotPassword',
    component: ForgotAuth,
    meta: {
      publicRoute: true,
      authType: 'password'
    }
  },
  {
    path: '/new-pin',
    name: 'ForgotPin',
    component: ForgotAuth,
    meta: {
      publicRoute: true,
      authType: 'pin'
    }
  },
  {
    path: '/reset-password/:id/:token',
    name: 'ResetPassword',
    component: ResetAuth,
    meta: {
      publicRoute: true,
      authType: 'password'
    }
  },
  {
    path: '/reset-pin/:id/:token',
    name: 'ResetPin',
    component: ResetAuth,
    meta: {
      publicRoute: true,
      authType: 'pin'
    }
  },
  //   {
  //     path: '/payment-link/account/:accountID',
  //     component: SendLoginLinkView,
  //     name: 'SendLoginLink',
  //     meta: { publicRoute: true, showNav: false },
  //     children: [
  //       {
  //         path: '/payment-link',
  //         component: SendLoginLinkView,
  //         name: 'SendLoginLink',
  //         meta: { publicRoute: true, showNav: false }
  //       }
  //     ]
  //   },
  {
    path: '/payments',
    name: 'PaymentList',
    component: PaymentListView
  },
  {
    path: '/payments/:paymentId',
    name: 'PaymentDetail',
    component: PaymentDetailView
  },
  {
    path: '/payment-methods/:accountID',
    name: 'PaymentMethodList',
    component: PaymentMethodListView
  },
  {
    path: '/message-settings',
    name: 'MessageSettings',
    component: MessageSettingsView
  },
  {
    path: '/invalid-token',
    name: 'InvalidToken',
    component: InvalidTokenView,
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: {
      beforeRouteEnter() {
        store.dispatch('logout')
      }
    }
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
    meta: {
      publicRoute: true
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.publicRoute) {
    // If route is public contine
    next()
  } else if (to.meta.invalid) {
    next({ name: 'AccountList' })
  } else if (store.getters.isAuthenticated) {
    if (to.name === 'PaymentMethodList' && !to.params.accountID) {
      try {
        const response = await axios.get('/api/accounts/', {
          params: { page_size: 1 }
        })
        const account = response.data.results[0]
        const accountId = account.id
        next({ ...to, params: { ...to.params, accountID: accountId } })
      } catch (error) {
        console.error('Error fetching account data:', error)
        next(false)
      }
    } else {
      next()
    }
  } else {
    // otherwise redirect to login
    next({ name: 'Login', query: { next: to.fullPath, ...to.query } })
  }
  next()
})

export default router
