import { i18n } from '@/locales/i18n-setup'
import { sum } from 'lodash-es'
import moment, { Moment } from 'moment'

export interface Dict {
  [key: string]: any
}

export interface Customer {
  accounts: Array<Account>
  address: string
  address2: string
  city: string
  country: string
  email: string
  first_name: string
  id: string
  last_name: string
  merchant: LimitedMerchant
  postal_code: string
  sms_phone_number: string
  state_province: string
  language: string
  is_ach_enabled: boolean
  disabled_payment_methods: Record<string, unknown>
}

export class Invoice {
  id: string
  account: Account
  dueDate: Moment
  amountDue: string
  waiveFees: boolean

  constructor(data: Dict) {
    this.id = data.id
    this.dueDate = moment(data.due_date)
    this.account = data.account
  }
}

export class Account {
  amount_due = 0
  total_amount = 0
  customer: Customer = {} as Customer
  customers = [] as string[]
  due_date = ''
  id = ''
  nickname = ''
  name = ''
  merchant_account = ''
  convenience_fees: Record<string, Record<string, number>>
  merchant_organization = {} as LimitedMerchant
  current_invoice = null as Dict
  autopay_customer = ''
  autopay_payment_method = ''
  merchant_name = ''
  is_ach_enabled = false
  scheduled_payment = ''
  max_charge = null as string
  latest_payment = null as string
  promise_objects = null as Dict[]
  schedule: string
  schedule_days: Array<string>
  waive_fees: boolean
  additional_data = null as Dict

  constructor(account: Dict) {
    Object.assign(this, account)
  }

  get isPastDue(): boolean {
    return (
      this.due_date &&
      moment(this.due_date) < moment() &&
      this.current_invoice !== null
    )
  }

  get isCurrent(): boolean {
    return this.current_invoice === null
  }

  calculateFee(amount: number, category: string): number {
    if (!amount) return 0
    if (this.current_invoice?.waive_fees) {
      return 0
    }
    let convenienceFee = 0
    const rate = this.convenience_fees[category]?.rate
    const flat = this.convenience_fees[category]?.flat

    if (rate) convenienceFee = amount * rate
    if (flat) convenienceFee += flat

    return Number(convenienceFee.toFixed(2))
  }

  getCurrentUserCustomer(userCustomers: Customer[]) {
    const match: Customer[] = userCustomers.filter((cust: Customer) =>
      this.customers.includes(cust.id)
    )

    if (match.length === 1) {
      return match[0]
    } else {
      return null
    }
  }
}

export interface MerchantOrganizationPaymentMethod {
  pricing_category: string
  enabled: boolean
}
export interface LimitedMerchant {
  id: string
  logo: string
  name: string
  website_url: string
  convenience_fee: string
  payment_methods: MerchantOrganizationPaymentMethod[]
  apply_convenience_fee_to_automated_payments: boolean
  apply_convenience_fee_to_scheduled_payments: boolean
  apply_convenience_fee_to_promises: boolean
  allow_consumer_pay_different_amount: boolean
  min_payment_percentage: string
  max_payment_percentage: string
  min_payment_amount: string
  max_payment_amount: string
  days_past_due: string
  merchant_only_autopay: boolean
  incomm_biller_id: string
  default_location_id: boolean
  max_max_charge: string
  max_average_charge: string
  max_monthly_volume: string
  show_balance_to_customer: boolean
  autopay_execution_time: string
  enable_custom_payments: boolean
}

export interface CustomerExtendedAccount {
  amount_due: number
  customer: Customer
  customers: Customer[] | string[]
  due_date: string
  id: string
  convenience_fees: Record<string, Record<string, number>>
  convenience_fee: string
  total_amount: string
  merchant_account: string
  is_ach_enabled: boolean
}

export interface AuthState {
  accessToken: string | null
  refreshToken: string | null
  lastActivity: number
  isInactive: boolean
  profile: Dict | null
  customers: Array<Customer> | null
  heartbeat: number | null
}

export interface DataState {
  openedDrawers: string[]
  busyCount: number
  customers: Customer[]
  accounts: Map<string, Account>
  currentAccount: Account
  currentInvoice: Invoice
  currentCustomer: Customer
  merchants: Map<string, LimitedMerchant>
  currentMerchant: LimitedMerchant | null
  payments: Payment2[]
  currentPayment: Payment2 | null
  binInfo: Dict | null
  currentAutopay: Dict | null
}

export interface DataGetters {
  drawers: Function
  hasDrawer: Function
  busyCount: Function
  isBusy: Function
  customers: Function
  accounts: Function
  currentAccount: Function
  currentInvoice: Function
  currentCustomer: Function
  getCustomerByAccountID: Function
  merchants: Function
  currentMerchant: Function
  payments: Function
  currentPayment: Function
  binInfo: Function
}

export interface AuthGetters {
  accessTokenTimeToLive: any
  refreshTokenTimeToLive: any
  isAuthenticated: any
  isInactive: any
  accessClaims: any
  refreshClaims: any
  profile: any
  getLanguage: any
}

export interface AuthActions {
  rehydrate: any
  login: any
  logout: any
  refreshAccess: any
  authHeartbeat: any
  continueSession: any
  getCustomersAndMerchants: any
}

export interface AuthStore {
  state: Function
  actions: AuthActions
  getters: AuthGetters
  mutations: any
}

export interface CardPaymentMethod {
  account_holder_name: string
  card_name: string
  default: boolean
  exp_month: number
  exp_year: number
  id: string
  masked_card_number: string
  nickname: string
  payment_type: string
  postal_code: string
  street_address: string
  pricing_category: string
  cvv2: number | null
  is_verified: boolean
  is_customer_visible: boolean
  is_merchant_visible: boolean
}

export interface FieldSchema {
  as: string
  name: string
  label: string // user friendly string
}

// The form schema shape
export interface FormSchema {
  fields: FieldSchema[] // fields
}

interface TransactionInterface {
  id: string
  created: string
  updated: string
  account: string
  account_name: string
  account_nickname: string
  payment_method: string | Dict
  amount: string
  status: string
  statuses: Dict
  masked_payment_method?: string
  payment_method_nickname: string
}

export class Transaction implements TransactionInterface {
  id = ''
  created = ''
  updated = ''
  account = ''
  account_name = ''
  account_nickname = ''
  merchant_name = ''
  payment_method = ''
  amount = ''
  convenience_fee = ''
  total_amount = ''
  status = ''
  payment_method_mask = ''
  payment_method_nickname = ''
  statuses: Dict = {
    REJECTED: i18n.t('labels.rejected'),
    DECLINED: i18n.t('labels.declined'),
    OK: i18n.t('labels.approved')
  }

  constructor(transaction: Dict) {
    Object.assign(this, transaction)
    this.status = this.statuses[transaction['status']]
  }
}

export interface Optin {
  category: string
  id: string
  media: string
  status: boolean
}

export class Payment {
  paymentMethod: Dict | CardPaymentMethod
  cvv2: string
  amount: number
  convenienceFee: number
  selected: boolean
  dirty: boolean
  total = 0
  id: string
  payment_type: string
  apply_towards_autopay: boolean

  constructor(
    paymentMethod: Dict | CardPaymentMethod,
    amount: number,
    selected: boolean,
    dirty: boolean,
    cvv2: string,
    payment_type: string
  ) {
    this.paymentMethod = paymentMethod
    this.amount = Number(amount)
    this.selected = selected
    this.cvv2 = cvv2
    this.dirty = dirty
    this.payment_type = payment_type
  }

  get as_dto(): Dict {
    return {
      payment_method: this.paymentMethod.id,
      ...(this.cvv2 && { cvv2: this.cvv2 }),
      amount: this.amount,
      total: Number(this.total),
      fee: Boolean(this.convenienceFee),
      payment_type: this.payment_type,
      apply_towards_autopay: this.apply_towards_autopay
    }
  }
}

export enum PaymentType {
  Autopay = 'AUTOPAY',
  Scheduled = 'SCHEDULED',
  Merchant = 'MERCHANT_UI',
  Consumer = 'CONSUMER_UI',
  Promised = 'PROMISED',
  Requested = 'REQUESTED_PAYMENT'
}

export enum PaymentStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
  Failed = 'FAILED'
}

export class PaymentSource {
  id: string
  paymentMethod: string
  amount: number
  transaction: Dict | null = null

  constructor(source: Dict) {
    this.id = source.id
    this.paymentMethod = source.payment_method
    this.amount = Number(source.amount)

    if (source.transaction) this.transaction = source.transaction
  }
}

export enum TransactionStatus {
  OK = 'OK',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Declined = 'DECLINED',
  Void = 'VOID',
  Refund = 'REFUND',
  Promise = 'PROMISED',
  PromiseConfirmed = 'PROMISE_CONFIRMED'
}

export class PaymentItem {
  id: string
  invoice: Invoice
  amount: number
  status: TransactionStatus

  constructor(item: Dict) {
    this.id = item.id
    this.invoice = item.invoice
    this.amount = Number(item.amount)
    this.status = item.status
  }
}

export class Payment2 {
  id = ''
  customer = ''

  merchant: LimitedMerchant
  created: Moment
  scheduledDate: Moment | null = null
  processedTime: Moment | null = null
  paymentType: PaymentType | null = null
  totalAmount: number
  totalAmountNoFee: number
  status: PaymentStatus | null = null
  paymentOrigin: string | null = null

  constructor(payment: Dict) {
    this.id = payment.id
    this.merchant = payment.merchant
    this.customer = payment.customer
    this.created = moment(payment.created)
    this.totalAmount = Number(payment.total_amount)
    this.totalAmountNoFee = Number(payment.total_no_fee_amount)

    if (payment.scheduled_date)
      this.scheduledDate = moment(payment.scheduled_date)

    if (payment.processed_time)
      this.processedTime = moment(payment.processed_time)

    this.paymentType = payment.payment_type
    this.status = payment.status
    this.paymentOrigin = payment.payment_origin
  }
}

export class PaymentDetail extends Payment2 {
  sources: PaymentSource[]
  items: PaymentItem[] | null

  constructor(detail: Dict) {
    super(detail)

    this.sources = detail.sources.map(
      (source: Dict) => new PaymentSource(source)
    )

    this.items = detail.items.map((item: Dict) => new PaymentItem(item))
  }

  convenienceFee() {
    const fees = this.sources.map(source =>
      Number(source?.transaction?.convenience_fee)
    )

    return sum(fees)
  }
}

export interface SendLinkPayload {
  sms_phone_number: string
  account_id: string
}

export interface PromiseToPay {
  account_name: string
  amount: string
  created: string
  due_date: string
  id: string
  cash: string
  isCoborrower: boolean
  is_ach_enabled: boolean
  confirmed: string
  scheduled_date: string
  status: string

  payment_method: any
}
